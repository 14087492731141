import React, { useState, useContext, useEffect } from 'react'
import { Grid, Tab, Tabs, Box, InputAdornment, TextField, Autocomplete, Typography, FormControl, InputLabel, Select, MenuItem, Button, Card, CardContent } from '@mui/material';
import { LoadingButton, TabContext, TabPanel } from '@mui/lab';
import FormEnviarInternINTOUCH from '../../components/transacciones/enviosInternacionales/formEnviarInternaINTOUCH'
import CardStadoSaldo from '../../components/cardStadoSaldo';
import FormEnviarInternMoEx from '../../components/transacciones/enviosInternacionales/formEnviarInternalMoEx';
import Calculadora from '../../components/transacciones/enviosInternacionales/calculador';
import FormEnviarLOOV from '../../components/transacciones/enviosInternacionales/formEnviarInternaLOOV';

function EnviarInternacional() {
    const [selectForm, setSelectForm] = useState('');

    return (
        <>
            <CardStadoSaldo select='inter' />
            <form style={{ marginTop: "20px" }}>
                <Grid
                    spacing={1}
                    bgcolor="backgroundColorPage"
                    sx={{ justifyContent: "center", display: "flex" }}
                    container
                >

                    <Grid item xs={12} sm={12} md={6} lg={12} xl={12}  >
                        <Box>
                            <Typography sx={{ color: "textColorTitle", textAlign: "center" }} variant='h5'>
                                Elige el servicio que deseas utilizar
                            </Typography>
                        </Box>

                        <div style={{ width: '100%', marginTop: 15 }}>
                            <FormControl sx={{ width: "100%" }}>
                                <InputLabel id="demo-select-services-labels">Elige el servicio</InputLabel>
                                <Select
                                    id="demo-select-services"
                                    label="Elige el servicio"

                                    onChange={(e) => { setSelectForm(e.target.value) }}
                                    value={selectForm}
                                >
                                    <MenuItem value="Loov">Enviar con LOOV MONEY (en prueba)</MenuItem>
                                    <MenuItem value="MoEx">Enviar con Money Exchange</MenuItem>
                                    <MenuItem value="INTOUCH">Enviar con INTOUCH</MenuItem>

                                </Select>
                            </FormControl>

                        </div>

                    </Grid>

                </Grid>
            </form>
            <>
                {selectForm === 'INTOUCH' ?
                    <FormEnviarInternINTOUCH />
                    :
                    <></>

                }
            </>

            <>
                {selectForm === 'MoEx' ?
                    <FormEnviarInternMoEx />
                    :
                    <></>

                }
            </>
            <>
                {selectForm === 'Loov' ?
                    <FormEnviarLOOV />
                    :
                    <></>

                }
            </>
        </>
    )
}

export default EnviarInternacional