import React, { useState, useContext, useEffect } from 'react'
import MenuAppBars from '../../components/appBar/appBarr'
import { Grid, Tab, Tabs, Box } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
import Envios from '../../components/transacciones/envios/envios';
import Recepciones from '../../components/transacciones/recepciones/recepciones';
import AppContext from '../../contexts/ServiceContext';
import EnviosCancelados from '../../components/transacciones/enviosCancelados/envios';
import CardStadoSaldo from '../../components/cardStadoSaldo';
import { TITLEPAGE } from '../../contexts/constantesVar';
import { NavLink, useParams } from 'react-router-dom';
import EnviosInter from '../../components/transacciones/enviosInternacionales/enviosInter';

function Transacction() {
  const { id } = useParams()

  const { typeUser, valideLogin, userId, userName, userCode, userPhone, dispatch, acciones } = useContext(AppContext)

  const [value, setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    if (JSON.parse(window.localStorage.getItem("enableTAdmins"))) {
    } else {
        window.localStorage.setItem("enableTAdmins", JSON.stringify({ valor: false, valorI: "", nameI: '', typeI: '', phoneI: '' }))
    }
    dispatch({
      type: TITLEPAGE,
      payload: "TRANSACCIONES"
    })
  }, [])
  return (
    <div >
      {acciones.includes('ver_info') ?
        <>
          <CardStadoSaldo />
          <Grid
            bgcolor="backgroundColorPage"
            sx={{
              marginTop: "20px",
            }}
          >
            <TabContext value={`${id}`} >
              <Box sx={{ bgcolor: 'background.paper' }}>
                <Tabs
                  value={`${id}`}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                >
                  <Tab value={'1'} component={NavLink} to = '/transaccion/1' label="Envios nacionales" />
                  <Tab value={'2'} component={NavLink} to = '/transaccion/2' label="Envios internacionales" />
                  <Tab value={'3'} component={NavLink} to = '/transaccion/3' label="Recepciones" />
                  <Tab value={'4'} component={NavLink} to = '/transaccion/4' label="Envios cancelados" />
                </Tabs>
              </Box>
              <TabPanel value={'1'} sx={{ paddingInline: "0px" }}><Envios /></TabPanel>
              <TabPanel value={'2'} sx={{ paddingInline: "0px" }}><EnviosInter /></TabPanel>
              <TabPanel value={'3'} sx={{ paddingInline: "0px" }}><Recepciones /></TabPanel>
              <TabPanel value={'4'} sx={{ paddingInline: "0px" }}><EnviosCancelados /></TabPanel>
            </TabContext>
          </Grid>
        </>
        :
        <></>
      }


    </div>
  )
}


export default Transacction